import React, { useState, useContext, createContext } from "react";
import * as userAction from "../helpers/auth";
import * as firebase from "firebase/app";
import { clearUserDirection } from '../helpers/userDirection';
import "firebase/auth";
import "firebase/analytics";
import { toast } from "react-toastify";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

firebase.analytics();

const initialNullSession = {
  token: undefined,
  expiresIn: 0,
  role: null,
  userUid: null,
  isLoggedIn: false
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [userSession, setUserSession] = useState({
    isLoggedIn: false
  });

  const {
    userUid, token: sessionToken, expiresIn, isLoggedIn: isUserLoggedIn, role
  } = userSession;

  const signIn = ({ email, password, role }) => {
    return userAction.userSignIn({ email, password, role })
      .then(data => {
        if (data.error) {
          toast.error(data.error);
        } else {
          const {
            jwtToken,
            exp,
            role,
            uid,
            validation: {
              // emailVerified, 
              phoneNumberVerified,
              // require: validationRequire
            }
          } = data;
          // if (validationRequire) {
          setUserSession({
            userUid: uid, token: jwtToken, expiresIn: exp,
            isLoggedIn: true, role, phoneNumberVerified
          });
          //   window.location = '/profile_update';
          // } else {
          //   window.location = toLocation;
          // }
        }
        return data;
      })
  };

  const signUp = ({ email, password, role }) => {
    return userAction.userSignUp({ email, password, role })
      .then(data => {
        if (/^The email address /.test(data.error)) {
          toast.error(data.error, {
            position: 'top-center',
            autoClose: 8000
          });
        } else if (data.error) {
          toast.error("Oops! Something went wrong");
        } else {
          const { jwtToken, exp, role, uid } = data;
          setUserSession({
            userUid: uid, token: jwtToken, expiresIn: exp, isLoggedIn: true, role
          });
        }
        return data;
      })
  };

  const signOut = () => {
    return userAction.userSignOut()
      .then(res => {
        setUserSession({
          ...initialNullSession,
          token: null
        });
        clearUserDirection();
        return res;
      })
  };

  const emailLinkSignIn = ({ email, role }) => {
    return userAction.userEmailSignIn({ email, role })
      .then(response => {
        return response;
      })
  };

  const resendVerificationEmail = ({ action = null, email, status = null }) => {
    return userAction.userEmailVerification({ action, email, status })
      .then(response => {
        return response;
      })
  }

  const resetPassword = ({ code, password, confirmPassword }) => {
    return userAction.userResetPassword({ code, password, confirmPassword })
      .then(response => {
        return response;
      })
  }

  const phoneResetPassword = ({ countryCode, phoneNumber, role }) => {
    return userAction.userPhoneResetPassword({ countryCode, phoneNumber, role })
      .then(response => {
        return response;
      })
  }

  const updatePassword = ({ password, confirmPassword }) => {
    return userAction.userSessionToken()
      .then(({ jwtToken, role }) => {
        return userAction.userUpdatePassword({ jwt: jwtToken, password, confirmPassword, role })
          .then(response => {
            return response;
          })
      })
  }

  const updateEmail = ({ oldEmail, newEmail, password, encryptedData }) => {
    return userAction.userUpdateEmail({ oldEmail, newEmail, password, encryptedData })
      .then(response => {
        return response;
      })
  };

  const getEmail = () => {
    return userAction.getEmail()
      .then(data => {
        return data;
      })
  }

  const setSessionToken = () => {
    return (
      userAction.userSessionToken()
        .then(({ jwtToken, exp, role, uid }) => {
          setUserSession({
            token: jwtToken,
            expiresIn: exp,
            role,
            userUid: uid,
            isLoggedIn: true
          })
          return jwtToken;
        }).catch(() => {
          setUserSession({
            ...initialNullSession,
            token: null
          });
        })
    )
  };

  const isAuthTokenValid = () => {
    const timeNowInUnix = Math.floor(new Date().getTime() / 1000);

    if (sessionToken && expiresIn > timeNowInUnix) return true;
    return false;
  };

  const userLoggedInAndSessionValid = isUserLoggedIn && isAuthTokenValid();

  return {
    userUid, sessionToken,
    expiresIn,
    isUserLoggedIn,
    role, signIn,
    signUp, signOut,
    emailLinkSignIn,
    resendVerificationEmail,
    resetPassword,
    phoneResetPassword,
    updatePassword,
    updateEmail,
    getEmail,
    setSessionToken,
    isAuthTokenValid,
    userLoggedInAndSessionValid
  };
}

export { AuthProvider, useAuth };