// const appNames = {
//   SushiVid: 'SushiVid',
//   GoShareLah: 'GoShareLah',
//   ConfirmPlusChop: 'ConfirmPlusChop'
// }

// const appsSessionConfig = {
//   brands: {
//     [appNames.SushiVid]: { url: 'https://go.sushivid.com/auth_backend/brands/sign_in' },
//     [appNames.GoShareLah]: { url: 'https://www.gosharelah.com/auth_backend/brands/sign_in' },
//     [appNames.ConfirmPlusChop]: { url: 'https://www.confirmpluschop.com/auth_backend/brands/sign_in' }
//   },
//   influencers: {
//     [appNames.SushiVid]: { url: 'https://5ea969a03d7a.ngrok.io/auth_backend/influencers/sign_in' },
//     [appNames.GoShareLah]: { url: 'https://www.gosharelah.com/auth_backend/influencers/sign_in' },
//     [appNames.ConfirmPlusChop]: { url: 'https://www.confirmpluschop.com/auth_backend/influencers/sign_in' }
//   }
// }
const appNames = {
  SushiVid: 'SushiVid',
  ConfirmPlusChop: 'ConfirmPlusChop',
  GoShareLah: 'GoShareLah',
  SushiVidForum: 'SushiVidForum'
}

const appsSessionConfig = {
  brands: {
    [appNames.SushiVid]: { url: `${process.env.REACT_APP_AUTH_SUSHIVID}/auth_backend/brands/sign_in` },
    [appNames.ConfirmPlusChop]: { url: `${process.env.REACT_APP_AUTH_CPC}/auth_backend/brands/sign_in` },
    [appNames.GoShareLah]: { url: `${process.env.REACT_APP_AUTH_GSL}/auth_backend/brands/sign_in` },
    [appNames.SushiVidForum]: { url: `${process.env.REACT_APP_AUTH_SUSHIVID_FORUM}/auth_backend/brands/sign_in` },
  },
  influencers: {
    [appNames.SushiVid]: { url: `${process.env.REACT_APP_AUTH_SUSHIVID}/auth_backend/influencers/sign_in` },
    [appNames.ConfirmPlusChop]: { url: `${process.env.REACT_APP_AUTH_CPC}/auth_backend/influencers/sign_in` },
    [appNames.GoShareLah]: { url: `${process.env.REACT_APP_AUTH_GSL}/auth_backend/influencers/sign_in` },
    [appNames.SushiVidForum]: { url: `${process.env.REACT_APP_AUTH_SUSHIVID_FORUM}/auth_backend/influencers/sign_in` },
  }
}

export { appNames, appsSessionConfig };