import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "../../hooks/useAuth";

const GraphqlClient = () => {
  const { sessionToken, setSessionToken, getToken, expiresIn, isUserLoggedIn } = useAuth();
  let httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL
  });

  const wsLink = new WebSocketLink(
    new SubscriptionClient(
      process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL, {
      reconnect: true,
      reconnectionAttempts: 10,
      connectionParams: () => ({
        headers: { Authorization: `Bearer ${sessionToken}` }
      })
    })
  );

  const linkHeader = setContext(async (request, previousContext) => {
    if (isUserLoggedIn) {
      const remainingTime = expiresIn - Math.floor(new Date().getTime() / 1000);

      if (remainingTime > 0) {
        // wsLink = new WebSocketLink(
        //   new SubscriptionClient(
        //     process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL, {
        //     reconnect: true,
        //     reconnectionAttempts: 20,
        //     connectionParams: () => ({
        //       headers: { Authorization: `Bearer ${sessionToken}` }
        //     })
        //   })
        // );

        return {
          headers: {
            Authorization: `Bearer ${sessionToken}`
          }
        }
      } else if (remainingTime <= -10800) {
        // Re-render after 3 hours
        const newToken = await setSessionToken();;

        // wsLink = new WebSocketLink(
        //   new SubscriptionClient(
        //     process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL, {
        //     reconnect: true,
        //     reconnectionAttempts: 20,
        //     connectionParams: () => ({
        //       headers: { Authorization: `Bearer ${newToken}` }
        //     })
        //   })
        // );

        return {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        }
      } else {
        // Get token for current action
        const newToken = await getToken();;

        // wsLink = new WebSocketLink(
        //   new SubscriptionClient(
        //     process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL, {
        //     reconnect: true,
        //     reconnectionAttempts: 20,
        //     connectionParams: () => ({
        //       headers: { Authorization: `Bearer ${newToken}` }
        //     })
        //   })
        // );

        return {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        }
      }
    }
  })

  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(async ({ message, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
        );
        if (message === "Could not verify JWT: JWTExpired" || message === "Malformed Authorization header") {
          console.log('extensions code', extensions.code)
          // const oldHeaders = operation.getContext().headers;
          // console.log('oldHeaders', oldHeaders)
          // const newToken = await getToken();
          // console.log('New Token', newToken)
          // operation.setContext({
          //   headers: {
          //     Authorization: `Bearer ${newToken}`
          //   }
          // })
          // console.log('new header', operation.getContext().headers)
          // console.log('Forwarding...')
          // return forward(operation);

          return null;
        }
      });
    if (networkError) {
      // console.log(`[Network error]: ${networkError}`);
      // props.history.push('/network-error') // redirect to network-error route
    }
  });

  // const logLinks = new ApolloLink((operation, forward) => {
  //   // https://www.apollographql.com/docs/react/api/link/introduction/#link-types
  //   console.log('operation', operation.getContext())
  //   console.log('forward', forward)

  //   return forward(operation);
  // })

  const link = ApolloLink.from([
    // logLinks,
    errorLink,
    linkHeader,
    splitLink
  ]);

  const apolloClient = new ApolloClient({
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache({
      typePolicies: {
        influencers: {
          fields: {
            authorizations: {
              merge(_ignore, incoming) {
                return incoming;
              }
            }
          }
        }
      }
    })
  });

  return apolloClient;
};

export default GraphqlClient;