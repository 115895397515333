import React from 'react';
import styleClass from '../../assets/styles/loaders.module.scss';

const Spinner = ({
  height = "100vh", width = '150px', background = "rgb(255, 255, 255)",
  children
}) => {
  return (
    <div className={styleClass.SvSpinnerContainer} style={{ height }}>
      <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={styleClass.SvSpinner} style={{ background }} width={width} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#e43631" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
      </svg>
      <div className={styleClass.LoadingText}>{children}</div>
    </div>
  )
}

export default Spinner;