const userSignUp = ({ email, password, role }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/sign_up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ email, password, role })
    }).then(response => {
      return response.json()
    })
  )
}

const userSignIn = ({ email, password, role }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ email, password, role })
    }).then(response => {
      return response.json()
    })
  )
}

const userEmailSignIn = ({ email, role }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ email, role })
    })
  )
}

const userSignOut = () => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/sign_out`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
  )
}

const userSessionToken = () => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/refresh_token`, {
      method: 'POST',
      credentials: 'include'
    }).then(response => {
      if (response.status === 200) return response.json();
      return Promise.reject(response.statusText)
    })
  )
}

const userResetPassword = ({ code, password, confirmPassword }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/reset_password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ code, password, confirmPassword })
    })
  )
}

const userPhoneResetPassword = ({ countryCode, phoneNumber, role }) => {
  let res = {}
  res[role] = { country_code: countryCode, phone_number: phoneNumber }
  return (
    fetch(
      `${process.env.REACT_APP_SOCIAL_MEDIA}/v1/${role}/reset_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(res)
    })
  )
}

const userEmailVerification = ({ action, email, status }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/email_link_sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ action, email, status })
    })
  )
}

const userUpdatePassword = ({ jwt, password, confirmPassword, role }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/password_update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ jwt, password, confirmPassword, role })
    })
  )
}

const userUpdateEmail = ({ oldEmail, newEmail, password, encryptedData }) => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/email_update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ oldEmail, newEmail, password, encryptedData })
    })
  )
}

const getEmail = () => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/get_email`, {
      method: 'POST',
      credentials: 'include'
    }).then(response => {
      return response.json()
    })
  )
}

export {
  userSignUp,
  userSignIn,
  userEmailSignIn,
  userSignOut,
  userSessionToken,
  userResetPassword,
  userPhoneResetPassword,
  userEmailVerification,
  userUpdatePassword,
  userUpdateEmail,
  getEmail
};
